.about{
    padding: 4rem;
    height: auto;
    background: #fcf7f6;
}

h1 {
    text-align: center;
    margin-bottom: 2em;
    margin-top: 3.5em;
}

.lang-box-me{
  text-align: center;
  margin: 0;
  padding: 0;
  
}

.row{
  display: flex;
  margin-bottom: 3em;
}

.column {
  flex: 50%;
  padding: 5px;
}

.text-container{
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 5em;
}

.text-container  p {
    color:black;
    font-size: 22px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.text__wrapper {
    position: relative;
    font-size: 30px;
    margin: 50px 0 45px;
  }

@media only screen and (max-width: 1024px) {
  .about{
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .row{
    flex-flow: column;
    padding: 2.5rem;
  }

  .first-image{
    margin-bottom: 3rem;
  }

  .text-container{
    width: 100%;
    padding: 0.5rem;
  }

  .text__wrapper > p {
   font-size: 20px; 
  }

  .lang-box{
    transform: scale(70%);
    margin-top: 50px;
    margin-bottom: 50px;
    height: 30px;
  }

  .text-container  p {
  
    font-size: 18px;
   
  }
}
