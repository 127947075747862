.title{
    font-style: italic;
    font-size: 12px;
}

.format{
    font-size: 7px;
}

.dimensions{
    font-size: 7px;
}

.year{
    font-size:7px
}


@media screen and (max-width: 800px) {

    .title{
        font-size: 7px;
    }

    .format{
        font-size: 6px;
    }

    .dimensions{
        font-size: 6px;
    }
    
    .year{
        font-size:6px;
    }

    .language-bttns{
    
        margin-left: 14rem;
        margin-right: 14rem;
        
    }

 
}

@media (min-width:500px) and (max-width: 960px){


    .title{
        font-size: 7px;
        display: inline-block;
        padding: 0.5rem 0.5rem;
    }

    .format{
        font-size: 6px;
        display: inline-block;
        padding: 0.5rem 0.5rem;
    }

    .dimensions{
        font-size: 6px;
        display: inline-block;
        padding: 0.5rem 0.5rem;
    }
    
    .year{
        font-size:6px;
        display: inline-block;
        padding: 0.5rem 0.5rem;
    }

    .language-bttns{  
        text-align: center;
    }
    

}


