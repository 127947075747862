.gallery-container{
    width: 100%;
    position: flex;
    overflow: scroll;
    overflow-x: hidden;
    background-color: #b8b0af;
}



.child-two{
    opacity: 0;
    transition: all 1s ease-in-out;
}

.child-two.active{
   
    opacity: 1;
    transform: translateY(-35%) !important;
    /*box*/
    padding: 23px;
    border-width: 2px;
    border-color: #767474;
    background-color: #696969;
    color: #fff;
    border-style: solid;
    border-radius: 14px;
    box-shadow: 0px 0px 22px rgba(66,66,66,.54);
    text-shadow: 0px 0px 27px rgba(66,66,66,.75);
}



@media screen and (max-width: 800px) {


    .child-two{
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    
    .child-two.active{
        opacity: 1;
        transform: translateY(-60%) !important; 
   
    }

}


@media (min-width:500px) and (max-width: 960px){
    .gallery-container{
        overflow: hidden;
    }

    .child-two{
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }

    .child-two.active{
        text-align: center;
        transform: translateY(-280%) !important;
    }
}


  