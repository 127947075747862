.aboutMe{
    padding: 4rem;
    height: 100%;
    background: #fcf7f6;
}

h1 {
    text-align: center;
    margin-bottom: 5em;
    margin-top: 3.5em;
}

.lang-box-me{
    text-align: center;
    margin: 0;
    padding: 0;
    
}

.text-image-container{
    position: relative;
    display: flex;
    align-items: baseline;
    
}

.first-column, .second-column {
    width: 50%;
    display: flex;
    /* Enable flex behavior for children */
    flex-flow: column;
    padding: 6rem;
}

.first-column, .second-column  p{
    color:black;
    font-size: 22px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }


@media screen and (max-width: 820px){

    .aboutMe{
        padding: 1;
    }

    .lang-box-me{
        text-align: center;
        padding: 0;
        
    }

    .text-image-container{
        margin-top: 120px;
        align-items: center;
        flex-flow: column;
        width: 100%;
        
    }

    .first-column, .second-column{
        width: 100%;
        padding: 0;
    }

    .first-column, .second-column p{
        font-size: 19px;
    }

    .second-column{
        padding: 0;
    }
    
}


