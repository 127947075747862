  html, body { 
    scroll-behavior: smooth; 
    overflow-x: hidden;
    max-width: 100%;
  }

  body::-webkit-scrollbar{
    display: none;
  }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  
}


.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
/*Gallery 1*/
.galleryOne {

 overflow-y: scroll;
 position:absolute;
 border: 1px solid #ccc;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

/*Contact */
.products {
  transition-duration: 2s;
  background-image: url('../public/Dibujos/Foi.jpg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


.sign-up {
  background-image: url('../public/Dibujos/Jenny.jpg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
